import { mapState } from 'vuex'

import { addressUtilsMixin } from '../../../shared/mixins/addressUtilsMixin'
import { apiMixin } from '../../../shared/mixins/apiMixin'
import { arrayUtilsMixin } from '../../../shared/mixins/arrayUtilsMixin'
import { fileUtilsMixin } from '../../../shared/mixins/fileUtilsMixin'
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin'
import { getItem } from '../../../../api/api-methods'
import { postExpense } from '../../../expenses/helpers/utilsExpense'
import { fetchGetInventoryByProduct } from '../../../products/helpers/productUtils'
import { fetchGetOrder, fetchPostCancellationOrder, fetchPutOrder } from '../../helpers/ordersUtils'

import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue'
import KnCheckBox from '../../../shared/components/KnCheckBox.vue'
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue'
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue'
import KnFormTitle from '../../../shared/components/KnFormTitle.vue'
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue'
import KnSelect from '../../../shared/components/KnSelect.vue'
import KnTextArea from '../../../shared/components/KnTextArea.vue'
import KnTextField from '../../../shared/components/KnTextField.vue'
import { fetchPostMovementInventory, fetchPutInventory } from '../../helpers/inventoryUtils'
import { saleUtilsMixing } from '../../mixins/saleUtilsMixin'
import { BASE_CANCELLATION_ORDER } from '../../helpers/states'

export default {
  components: {
    KnFormSubtitle,
    KnSelect,
    KnLocalAlert,
    KnFormActionButtons,
    KnTextArea,
    KnFormTitle,
    KnBackToTopButton,
    KnCheckBox,
    KnTextField
  },
  mixins: [
    fileUtilsMixin,
    validationFormMixin,
    apiMixin,
    addressUtilsMixin,
    arrayUtilsMixin,
    saleUtilsMixing,
  ],
  data() {
    return {
      tabs: [
        { name: 'Solicitud de cancelación', value: 100 },
      ],
      valueDeterminate: 100,
      routerName: 'Ordenes',
      orderId: null,
      cancellation: {
        orden: this.orderId,
        motivo_cancelacion: null,
        estatus_cancelacion: null,
        comentarios: '',
        aprobador: null,
        solicitado_por_cliente: false,
        autor: null,
        institucion_educativa: null
      },
      expense: {
        proveedor: null,
        empleado: null,
        categoria: null,
        sub_categoria: null,
        sub_total: 0.00,
        total_impuestos: 0.00,
        costo_envio: 0.00,
        total_descuento: 0.00,
        total_egreso: 0.00,
        descuento_aplicado: false,
        forma_de_pago: null,
        moneda: null,
        cuenta_origen: null,
        estatus: null,
        centro_de_costos: null,
        autor: null,
        institucion_educativa: null
      },
      order: {
        numero_orden: null,
        estatus_orden: null,
        alumno: null,
        datos_facturacion: null,
        direccion: null,
        peso_volumetrico: null,
        sub_total: null,
        total_impuestos: null,
        costo_envio: 0.00,
        total_descuento: 0.00,
        total_orden: null,
        id_moneda: null,
        descuento_aplicado: false,
        fecha_elaboracion: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      orderDetails: [],
      cancellationStatusId: null, // Debera ser Aprobada
      /** Arrays para cancelacion */
      cancellationReasons: [],
      cancellationStatus: [],
      adminEmployees: [],
      /** ****** */
      /** Arrays para egreso */
      expenseCategories: [],
      expenseSubcategories: [],
      paymentMethods: [],
      coins: [],
      expenseStatus: [],
      bankAccounts: [],
      /** ****** */
      /** Arrays para inventarios */
      movementTypes: [],
      movementReasons: [],
      /** ****** */
      products: [],
      students: [],
      shippingTypes: [],
      measurementUnits: [],
      items: [],

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
      loadingPage: false,
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return 'Crear cancelación'
    },
    isEditMode() {
      return this.order !== null
    },
    successAlertText() {
      return this.isEditMode ? 'Cancelación creada con éxito!' : 'Cancelación creada con éxito!'
    },
    successAlertType() {
      return this.isEditMode ? 'info' : 'success'
    },
    enableBtnAccept() {
      return true
    },
  },
  async created() {
    this.loadingPage = true
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      this.orderId = this.validateOrderId(this.$route.params.id);
      await this.loadDataCancellation()
      await this.constructorOrder()

    } catch (error) {
      console.error('Error in created:', error);
      this.error = "Error al cargar datos. ";
      this.setErrorState('Error al cargar datos. Por favor, inténtelo de nuevo.')
    } finally {
      this.loadingPage = false
      this.setLoadingState(false)
    }
  },
  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = this.successAlertType;
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion
    setTabValue(val) {
      this.valueDeterminate = val
    },
    validateOrderId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = "Formato de ID no válido.";
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },
    async loadDataCancellation() {
      const [
        cancellationReasonsRes,
        cancellationStatusRes,
        expenseCategoriesRes,
        expenseSubcategoriesRes,
        paymentMethodsRes,
        coinsRes,
        bankAccountsRes,
        adminEmployeesRes,
      ] = await Promise.all([
        getItem(`/app-ordenes/filters/mv-motivo-cancelacion?estatus_sistema=true&limit=100`),
        getItem(`/app-ordenes/filters/mv-estatus-cancelacion?estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/categoria-egreso?institucion_educativa=1&estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/sub-categoria-egreso?institucion_educativa=1&estatus_sistema=true&limit=100`),
        getItem(`/app-ordenes/filters/mv-tipo-pago?estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/moneda?institucion_educativa=1&estatus_sistema=true&limit=100`),
        getItem(`/app-administracion/filters/cuenta?estatus_sistema=true&limit=100`),
        getItem(`/app-personas/filters/empleado?estatus_sistema=true`)
      ]);

      this.cancellationReasons = cancellationReasonsRes.results
      this.cancellationStatus = cancellationStatusRes.results
      this.expenseCategories = expenseCategoriesRes.results
      this.expenseSubcategories = expenseSubcategoriesRes.results
      this.paymentMethods = paymentMethodsRes.results
      this.coins = coinsRes.results
      this.bankAccounts = bankAccountsRes.results
      this.adminEmployees = adminEmployeesRes.results
    },
    async createObjects() {
      this.setLoadingState(true, 'Creando cancelacion de orden', 'info');
      try {
        this.constructorCancellation()
        const { res, ok } = await fetchPostCancellationOrder(this.cancellation)
        if (!ok) this.setErrorState('Error al crear la cancelacion de orden', res.e);
        const status = this.findOrderStatusByCancellationStatus(this.cancellation.estatus_cancelacion)
        if (status) {
          await this.approveCancellation()
          this.setSuccessState('Cancelacion creada exitosamente')
        }
        else this.setSuccessState('Cancelacion creada exitosamente')
      } catch (error) {
        console.error('Error al intentar crear los objetos relacionados a solicitud de cancelacion', error);
      }
    },
    async approveCancellation() {
      this.constructorExpense()
      const status = await this.getStatusExpenseAprove()
      await postExpense({
        ...this.expense,
        estatus: status.id
      })
      await this.updateInventory()
      await this.updateOrderStatusToCancelled()
    },
    async updateInventory() {
      this.setLoadingState(true, 'Actualizando inventario...', 'info');
      try {
        const orderDetailsRes = await getItem(`app-ordenes/filters/detalle-orden?orden=${this.orderId}&estatus_sistema=true`);
        const orderDetails = orderDetailsRes.results;

        for (const detail of orderDetails) {
          const inventoryRes = await fetchGetInventoryByProduct(detail.producto.id, detail.producto.opcion_producto, this.institutionId);
          const inventory = inventoryRes.results[0];

          const cantidadDisponible = parseInt(inventory.cantidad_disponible);
          const unidades = parseInt(detail.unidades);

          if (isNaN(cantidadDisponible) || isNaN(unidades)) {
            throw new Error(`Cantidad disponible o unidades no son números válidos: ${cantidadDisponible}, ${unidades}`);
          }

          const objInventory = {
            ...inventory,
            producto: inventory.producto.id,
            opcion_producto: inventory.opcion_producto.id,
            institucion_educativa: inventory.institucion_educativa.id,
            autor: inventory.autor.id,
            unidad_medida: inventory.unidad_medida.id,
            almacen: inventory.almacen.id,
            cantidad_disponible: cantidadDisponible + unidades,
          };

          const { ok, res } = await fetchPutInventory(objInventory);
          if (ok) {
            await this.createInventoryMovement(res, detail);
          } else {
            this.setErrorState('Error al actualizar inventario');
            return;
          }
        }
        this.setSuccessState('Inventario actualizado con éxito');
      } catch (error) {
        this.setErrorState('Error al intentar actualizar inventario');
        console.error('Error al intentar actualizar inventario:', error);
      } finally {
        this.setLoadingState(false);
      }
    },

    async createInventoryMovement(inventory, detail) {
      try {
        const movementType = await this.getOrCreateMovementType(BASE_CANCELLATION_ORDER.MOVEMENT_TYPE, this.userData.id);
        const movementReason = await this.getOrCreateMovementReason(BASE_CANCELLATION_ORDER.MOVEMENT_REASON, this.userData.id);

        const initialAmount = parseInt(inventory.cantidad_disponible);
        const amountOfMovement = parseInt(detail.unidades);
        const precioUnitario = parseInt(detail.precio_unitario);

        if (isNaN(initialAmount) || isNaN(amountOfMovement) || isNaN(precioUnitario)) {
          throw new Error('Los valores de inventario inicial, cantidad de movimiento o precio unitario no son válidos.');
        }

        const finalAmount = initialAmount + amountOfMovement;
        const valueOfMovement = precioUnitario * amountOfMovement;

        const movementObj = {
          id_producto: inventory.producto,
          id_tipo_movimiento: movementType.id,
          id_motivo_movimiento: movementReason.id,
          id_almacen: inventory.almacen,
          inventario_inicial: initialAmount,
          cantidad_movimiento: amountOfMovement,
          inventario_final: finalAmount,
          precio_unitario: precioUnitario,
          valor_movimiento: valueOfMovement,
          id_moneda: detail.orden.moneda,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        };

        const response = await fetchPostMovementInventory(movementObj);
        if (!response)
          throw new Error('No se pudo crear el movimiento de inventario.');
      } catch (error) {
        this.setErrorState('Error al intentar crear el movimiento de inventario');
        console.error('Error al intentar crear el movimiento de inventario:', error);
        throw error;
      }
    },
    async getStatusExpenseAprove() {
      const result = await getItem('app-administracion/filters/estatus-egreso?dato=aprobado&estatus_sistema=true')
      return result.results[0]
    },
    async updateOrderStatusToCancelled() {
      const statusCancelled = await getItem('/app-ordenes/filters/mv-estatus-orden?estatus_sistema=true&limit=100&dato=cancelada')
      const status = statusCancelled.results[0]
      const orderCancelled = await fetchPutOrder({
        ...this.order,
        estatus_orden: status
      })
      return orderCancelled
    },
    async updateObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true

        await this.updateOrderStatus()

        this.loading = false
        if (this.errors.length > 0) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        console.error('Error al intentar actualizar inventario o crear movimiento inventario')
      }
    },
    async save() {
      this.createObjects()
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName })
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    findOrderStatusByCancellationStatus(status) {
      const result = this.cancellationStatus.find(e => e.id === status && e.dato.toLowerCase().includes('aprobada'));
      return result;
    },
    findProductPrice() {
      const product = this.products.find(p => p.id === this.productId)
      const productPrice = product.precios.find(pr => pr.distribuidor === this.institutionId)
      this.distributionPrice = productPrice.precio_distribuidor
      this.inventory.id_moneda = productPrice.moneda
    },
    getFullName(personalData) {
      const { primer_nombre, segundo_nombre, apellido_paterno, apellido_materno } = personalData;
      const firstName = primer_nombre || '';
      const secondName = segundo_nombre || '';
      const lastName1 = apellido_paterno || '';
      const lastName2 = apellido_materno || '';
      return `${firstName} ${secondName} ${lastName1} ${lastName2}`.trim();
    },
    async constructorCancellation() {
      this.cancellation.autor = this.userData.id
      this.cancellation.institucion_educativa = this.institutionId
      this.cancellation.orden = this.orderId
    },
    async constructorExpense() {
      this.expense.sub_total = parseFloat(this.order.sub_total)
      this.expense.total_impuestos = parseFloat(this.order.total_impuestos)
      this.expense.costo_envio = parseFloat(this.order.costo_envio)
      this.expense.total_descuento = parseFloat(this.order.total_descuento)
      this.expense.total_egreso = parseFloat(this.order.total_orden)
      this.expense.descuento_aplicado = this.order.descuento_aplicado
      this.expense.moneda = this.order.moneda.id
      this.expense.centro_de_costos = 1
      this.expense.autor = this.userData.id
      this.expense.institucion_educativa = this.institutionId
      this.expense.comentarios = `Egreso por cancelación. Orden: ${this.order.numero_orden}`
    },
    async constructorOrder() {
      const { res: orderObj } = await fetchGetOrder(this.orderId)
      const personalData = await getItem(`/app-personas/datos-personales/${orderObj.alumno.datos_personales}`)
      orderObj.alumno.datos_personales = this.getFullName(personalData)
      this.order = orderObj
    },
  }
}