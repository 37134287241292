
import { postItem, putItem } from "../../../api/api-methods";

const BASE_URL = 'app-administracion/egreso';

export const postExpense = async (expense, receipts) => {
  let arrayReceipts = receipts ? [receipts] : [];
  try {
    const URL = `${BASE_URL}`;
    const responseData = await postItem(URL, {
      ...expense,
      comprobantes: arrayReceipts
    });
    return responseData;
  } catch (error) {
    console.error('Error al intentar crear egreso:', error);
    throw error;
  }
};

export const putExpense = async (updatedExpense, expenseId) => {
  try {
    const URL = `${BASE_URL}/${expenseId}`;
    const responseData = await putItem(URL, updatedExpense);
    return responseData;
  } catch (error) {
    console.error('Error al intentar actualizar egreso:', error);
  }
};